<template>
    <div class="information">
        <div class="bigImg bgimg" style="backgroundimage:url('../../assets/img/skin/bigimg1.jpg'); ">
            <!-- <img src="../../assets/img/skin/bigimg1.jpg" alt=""> -->
        </div>
        <div class="main fixed"> <div class="left wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;">
        <div class="sideMenu">
            <div class="sideTit"> <a>
            <h3>商会资讯 </h3>
            <p>gongshanglianlingdao </p>
            </a> </div>
            <div class="mobileTit"><a type="button">商会资讯</a></div>
            <ul class="sideMenuNav">
                <li :class="item.on?'on':''" v-for="(item,index) in menuList" :key="index"  @click="cutmenu(index,item.id)"><i>&nbsp;</i><a href="javascript:;">{{item.name}}</a></li>
            </ul>
        </div>
        <app-config></app-config>
        </div>
        
            <!--left end-->
            
            <div class="right wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
                <div class="path">
                <h3>通告公告</h3>
                <div class="pathdiv"> <a href="/">主页</a> &gt; <span>&gt;</span><a >商会资讯</a> &gt; <span>&gt;</span><a href="" class="on">通告公告</a> &gt; </div>
            </div>
                <ul class="organize" v-if="!contentFlag">
                <li class="fixed" v-for="(item,index) in article.data" :key="index"  @click="getArticleDetails(item.id)">
                    <a href="javascript:;" >
                        <div class="photo"><img :src="item.head_image" alt="" title=""></div>
                        <div class="organzineText">
                            <h3>{{item.title}}</h3>
                        </div>
                    </a>
                 </li>
            </ul>

        <div class="rightBox" v-if="contentFlag">
        <el-button type="text" icon="el-icon-back" @click="contentFlag=false">返回列表</el-button>
            <div class="downLoadTit">
              <h1>{{content.title}}</h1>
              <div class="source">时间：{{content.created_at}}</div>
            </div>
        <div class="content fixed">
              <div>
              
                  <div class="content">
                      <div v-html="content.content">
                          
                      </div>
                    </div>
                </div>
            </div>
      </div>
                <!-- <div class="paged"> <li><span class="pageinfo">共 <strong>1</strong>页<strong>{{article.total}}</strong>条记录</span></li>
        </div> -->
                </div>
            <!--right end--> 
            
        </div>

    </div>
</template>

<script>
import AppConfig from "@/components/ahshconfig"

    export default {
         components:{
            AppConfig
        },
        data() {
            return {
                menuList:'',
                article:'',
                id:'',
                content:'',
                contentFlag:false,
            }
        },
        created(){
          
          this.id = this.$route.query.id
          this.getmenuList(this.id)
        },
        methods:{
          getmenuList(id){
            this.$http.get('/api/ahsh/children_menu/'+id).then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.menuList = data
                console.log(this.menuList)
                this.getArticle(data[0].id)
              }
            })
          },
          getArticle(id){
              let ajax = {
                  menu_id:id,
                  page:1,
                  page_size:10
              }
              this.$http.post('/api/ahsh/article_list',ajax).then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.article = data.data
                console.log(this.article)
              }
            })
          },
          getArticleDetails(id){
            this.$http.get('/api/ahsh/article/'+id).then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.content = data
                this.contentFlag = true
                console.log(data)
              }
            })
          },
          cutmenu(i,id){
            this.clearStyle()
            this.getArticle(id)
            this.contentFlag = false
            this.menuList[i].on = true
          },
          clearStyle(){
            let data = this.menuList
            for (let index = 0; index < data.length; index++) {
                data[index].on = false
            }
          },
        },
    }
</script>

<style lang="less" scoped>
    .information{
        .bgimg{
            background-image:url('../../assets/img/skin/bigimg2.jpg');
        }
    }
</style>